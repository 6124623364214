import React, { useEffect, useState } from "react";
import { message, Tabs } from "antd";
import "./style.scss";
import Web3 from "web3";
import CustomModal from "../../../components/elements/modal";
import * as AuthAPI from "../../../api/auth";
import { signMessage, CHAIN, walletConnector } from "../../../constants";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { useUIContext } from "../../../hook/AppContext";
import { checkTypeWallet, networkValidation } from "../../../ultis/common";
import { useWeb3React } from '@web3-react/core'

function ConnectWallet(props) {
  const {
    setIsShowingLoginPopup,
    setAuthToken,
    setRefreshToken,
    setUser,
    setStatusModal,
    history,
    chains
  } = useUIContext();
  const { TabPane } = Tabs;
  const [accounts, setAccounts] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState();
  const { activate, library, account } = useWeb3React()

  useEffect(()=>{setActiveTab(CHAIN?._id)},[])

  const getSignMessage = async (address) => {
    const res = await AuthAPI.getNonce({ address });
    if (res.data > 0) {
      return `${signMessage} Nonce: ${res.data}`;
    }
    return `${signMessage}`;
  };

  const connectMetamask = async (type) => {
    if (!window.ethereum) {
      // message.warning("You have to install Metamask extension!!");
      setStatusModal({
        isChangeNet: {
          type: true,
          title: "Login failed",
          value: `You have to install Metamask extension!`,
        },
      });
      return;
    }

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    if (accounts[0]) {
      setAccounts(accounts);
    }
    const chainInfo = CHAIN
    const web3 = new Web3(Web3.givenProvider);
    const chainId = await web3.eth.net.getId();
    try {
      let check = await networkValidation({
        chainId,
        requiredChainNetwork: chainInfo,
        isMetamask: true,
        isLogin: true
      });
      if (check){
        let mess = await getSignMessage(accounts[0]);
        let sig1 = await web3.eth.personal.sign(mess, accounts[0]);
        if (sig1) {
          const dataLogin = {
            address: accounts[0],
            signature: sig1,
            type: chainInfo?.connectType[0],
          };
          setFormData(dataLogin);
          handleLoginEx(dataLogin);
        }
      }
      
      
      
      
    } catch (err) {
      message.error(err.message);
    }
  };

  const connectWalletCon = async () => {
    // message.info("Coming soon!!!")
    // return
    
    try {
      // Create a connector
      let walletConnect = walletConnector
      await activate(walletConnect)
    } catch (err) {
    }
  };


  useEffect(() => {
    if(library) {
      signWalletmess(library)
    }
  }, [library])

  const signWalletmess = async (connector) => {
    try {
      const chainInfo = chains?.find(el => el?._id == activeTab)
      let type = 2
      let mess = await getSignMessage(account);
      let sig1 = await connector.eth.personal.sign(mess, account);
      if (sig1) {
        const dataLogin = {
          address: account,
          signature: sig1,
          type: type,
        };
        setFormData(dataLogin);
        handleLoginEx(dataLogin);
      }
    } catch (err) {
      message.error(err.message);
    }
  }

  useEffect(() => {
    if (formData.address ) {
      setUser({
        address: formData.address?.toLowerCase(),
        CHAIN,
        walletType: checkTypeWallet(CHAIN?.shortName),
        walletCon: formData.type
      });
    }
  }, [formData, chains])

  const handleLoginEx = async (data) => {
    try {
      const res = await AuthAPI.connectWallet(data);
      setAuthToken(res.access_token);
      setRefreshToken(res.refresh_token);
      setIsShowingLoginPopup(false);
      history.push('/vesting')
    } catch(err) {
      message.error("Login Failed!")
    }
  };

  const renderTab = () => {
    return <>
    <div
            className="wallet-connect-btn"
            onClick={() => {
              setIsShowingLoginPopup(false);
              connectMetamask();
            }}
          >
            <img alt=""src="/images/metamask-icon.svg"></img>
            <div className="wallet-type">Metamask</div>
            <img alt=""className="wallet-arrow" src="/images/arrow-right.svg"></img>
          </div>
          <div
            className="wallet-connect-btn"
            onClick={() => {
              setIsShowingLoginPopup(false);
              connectWalletCon();
            }}
          >
            <img alt=""className="wallet-icon" src="/images/wallet-connect.svg"></img>
            <div className="wallet-type">WalletConnect</div>
            <img alt=""className="wallet-arrow" src="/images/arrow-right.svg"></img>
          </div>
          </>
    
 
  }

  return (
    <CustomModal
      visible={true}
      footer={null}
      onCancel={() => setIsShowingLoginPopup(false)}
      className={`dark-modal connect-wallet-modal`}
      closeIcon={<></>}
    >
      <div className="popup-content">
        <div className="title-popup">Connect wallet</div>
        <div className="wallet-box-line"></div>
          {renderTab()}
      </div>
    </CustomModal>
  );
}

export default ConnectWallet;
