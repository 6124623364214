import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import { Row} from "antd";
import moment from "moment";
import CountDownBox from "../../../elements/countdown-box";
import TableItem from "../table-item"
import { withContext } from "../../../../hook/AppContext";
import useBlockchainFunc from "../../../../ultis/blockchain";
import { getUser, convertAmount } from "../../../../ultis/common";
import { useUIContext } from "../../../../hook/AppContext";



function RoundItem(props) {
  const { contractAddress, myAllocation, setQuantity } =props;
  const { setStatusModal } = useUIContext();
  const { getVestingAddressInfo, getVestingInfo, claimVesting } = useBlockchainFunc();
  const [roundData, setRoundData] = useState()

  const getVestingRound = async (reload=false) => {
    const res = await getVestingInfo(contractAddress)
      let myAllocation
      if(getUser()?.address){
        const res2 = await getVestingAddressInfo(contractAddress)
        if(res2.TotalAllocation > 0){
          if(!reload) setQuantity((prevState) => prevState +1)
          myAllocation = {
            totalAllocation: convertAmount(res2.TotalAllocation),
            unlockedAmount: convertAmount(res2.UnlockedAmount),
            claimableAmount: convertAmount(res2.ClaimableAmount),
            claimedAmount: convertAmount(res2.ClaimedAmount),
            isClaim: res2.ClaimableAmount > 0 
          }
        }
      }
    
      const data = {
        name: res.Name,
        startTime: res.StartUnlockTime,
        endTime: res.EndTime,
        nextVestingTime: res.NextVestingTime,
        totalAllocation: convertAmount(res.TotalAllocation),
        unlockedAmount: convertAmount(res.UnlockedAmount),
        claimableAmount: convertAmount(res.ClaimableAmount),
        claimedAmount: convertAmount(res.ClaimedAmount),
        contractAddress: contractAddress,
        myAllocation: myAllocation
      }
    setRoundData(data)
  }

  useEffect(getVestingRound,[])

  const handleVesting = async(rs) => {
    if (rs.isRefesh) {
      setStatusModal({ isPending: false, isSuccess: true });
      await getVestingRound(true)        
    }
  }


  const onClickClaim = async() => {
    await claimVesting(contractAddress, handleVesting)
  }


  const renderContent = useMemo (()=> { 
    if(!myAllocation || (myAllocation && roundData?.myAllocation)){
      return <div className="round-container">
    <Row justify="space-between">
      <div className="round-container__info">
        <div className="info-title">
          {roundData?.name}
          <a href={`${process.env.REACT_APP_BSC_URL}/address/${roundData?.contractAddress}`} target="_blank">
            <img alt="" src="/images/link-page.svg"/>
          </a>
        </div>
        <div className="vesting-schedule">Vesting Schedule:</div>
        <div className="vesting-schedule__time">
          {moment.utc(moment.unix(roundData?.startTime)).format("DD/MM/yyyy")} - {moment.utc(moment.unix(roundData?.endTime)).format("DD/MM/yyyy")}
        </div>
        <div className="unlock-in"> {roundData?.nextVestingTime > 0 ? "Unlock In ": "Unlock All At"}</div>
        {roundData?.nextVestingTime > 0 ? <CountDownBox callback={getVestingRound} time_end={roundData?.nextVestingTime}/> : <div>{moment.utc(moment.unix(roundData?.endTime)).format("DD/MM/yyyy")}</div>}
      </div>
      <TableItem roundData={roundData}/>
      <TableItem roundData={roundData} isMyAllocation={true}/>
    </Row>
    {roundData?.myAllocation?.isClaim && <div onClick={onClickClaim} className="claim-btn" >Claim</div>}

   
  </div>} else return null
  },[myAllocation, roundData])

  return renderContent
}

export default withContext(RoundItem);
