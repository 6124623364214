import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col} from "antd";
import { withContext } from "../../../../hook/AppContext";

function TableItem(props) {
  const { roundData, isMyAllocation } =props;  
  const [data, setData] = useState();

  useEffect(() => {
    if(isMyAllocation) setData(roundData?.myAllocation)
    else setData(roundData)
  },[roundData, isMyAllocation])
    
  return (
  <div className="table-container">
    <div className="table-container__title">{isMyAllocation ? "Your Stats" : "Overall Stats"}</div>
    <Row className="table-container__content" justify="space-between">
      <Col xs={24} md={12} className="content-item">
        <img src="/images/allocation.svg"/>
        <div className="content-item__title">
          Allocation
          <div className="content-item__number">
            {data?.totalAllocation || "--"} <div className="content-item__token"> MSTR</div>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12} className="content-item">
        <img src="/images/unlocked_amount.svg"/>
        <div className="content-item__title">
          Unlocked Amount
          <div className="content-item__number">
            {data?.unlockedAmount || "--"} <div className="content-item__token"> MSTR</div>
          </div>
        </div>
      </Col>
    </Row>
    <Row className="table-container__content" justify="space-between">
      <Col xs={24} md={12} className="content-item">
        <img src="/images/claimed_amount.svg"/>
        <div className="content-item__title">
          Claimed Amount
          <div className="content-item__number">
            {data?.claimedAmount || "--"} <div className="content-item__token"> MSTR</div>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12} className="content-item">
        <img src="/images/claimable_amount.svg"/>
        <div className="content-item__title">
          Claimable Amount
          <div className="content-item__number">
            <span>{data?.claimableAmount || "--"}</span> <div className="content-item__token"> MSTR</div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
  );
}

export default withContext(TableItem);
