import './style.scss';


function CloseButton ({ children }) {



    const initArgs = () => {
    }

    initArgs();

    return (
        <div className="close-icon">
            <img alt="" src="/images/btn-close.svg"></img>
        </div>
    )
}

export default CloseButton;
