import React, { useEffect, useMemo } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Header from "../layouts/components/header";
import { I18nProvider, AppI18nProvider } from "../layouts/i18n";
import "../styles/App.scss";
import { AppUIProvider } from "../hook/AppContext";
import ConnectWallet from "./connect-wallet";
import MainTainPage from "./maintain";
import ThemeProvider from "../hook/ThemeProvider";
import Vesting from './vesting';
import SwiperCore, { Mousewheel, Pagination, Navigation } from 'swiper';
import { VERSION } from "../constants";
import { getUser } from "../ultis/common";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from "web3";

SwiperCore.use([Mousewheel, Pagination, Navigation]);


const getLibrary = (provider) => {
  const library = new Web3(provider)
  library.pollingInterval = 12000
  return library
}


function App() {
  useEffect(() => {
    AOS.init({once: true,})
    const localVer = localStorage.getItem("version");
    if (localVer) {
      if (VERSION != localVer) {
        localStorage.clear();
        localStorage.setItem("version", VERSION);
        window.location.reload(true);
      }
    } else {
      localStorage.clear();
      localStorage.setItem("version", VERSION);
    }
  }, []); 
  

  return <AppI18nProvider>
      <I18nProvider>
        <ThemeProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
          <Router>
        <AppUIProvider>
            <div>
              <Header />
            <Switch >
              <Route exact path="/">
                <Redirect to="/vesting"/>
              </Route>
              <Route exact path="/vesting">
                <Vesting/>
              </Route>
              <Route exact path="/connect-wallet">
                <ConnectWallet />
              </Route>
              <Route exact path="/maintain">
                 <MainTainPage/>
              </Route>
            </Switch>
            </div>
        </AppUIProvider>
          </Router>
          </Web3ReactProvider>
        </ThemeProvider>
      </I18nProvider>
    </AppI18nProvider>;
}

export default App;
