import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const MaxUint = '115792089237316195423570985008687907853269984665640564039457584007913129639935'


const CHAIN = {
    _id: 0,
    name: 'BNB Chain',
    chainId: process.env.REACT_APP_BSC_NETWORK,
    hexChainId: "0x38",
    textWarning: 'Binance Smart Chain - Mainnet',
    url: process.env.REACT_APP_BSC_URL,
    suffixToken: 'BEP-20',
    explorerName: 'BSCscan',
    suffixKey: '',
    shortName: 'BSC',
    key: 'BSC',
    nativeToken: 'BNB',
    image: "/images/chain/bsc.svg",
    rpc: process.env.REACT_APP_BSC_PROVIDER,
    connectType: [1, 2]
}

const THEME = {
    dark: 'dark',
    light: 'light'
}

const signMessage =
  "Welcome. By signing this message you are verifying your digital identity. This is completely secure and does not cost anything!";

const USER_WALLET_TYPE = {
    metamask: 1,
    walletConnect: 2,
    terraStation: 3,
    walletConnectTerra: 4,
    metamaskAvax: 5,
    walletConnectAvax:6,
    metamaskOkex: 7,
    walletConnectOkex: 8,
}

const WALLET_TYPE = {
    bsc: 1,
    terra: 2,
    avax: 3,
    okex:4
}

const CSS_TYPE = {
    slideRange: "slide_range"
}

const ROUND_LIST = [
    process.env.REACT_APP_PRIVATE_SALE,
    // process.env.REACT_APP_MARKETING_AND_LISTING,
    // process.env.REACT_APP_ECOSYSTEM,
    // process.env.REACT_APP_GAMING_ISSUANCE,
    // process.env.REACT_APP_STAKING_ISSUANCE,
    // process.env.REACT_APP_FOUNDATION,
    process.env.REACT_APP_TEAM_AND_ADVISOR
]



export {
    MaxUint,
    THEME,
    USER_WALLET_TYPE,
    CHAIN,
    signMessage,
    WALLET_TYPE,
    CSS_TYPE,
    ROUND_LIST
}

export const DEFAULT_ITEMS_PER_PAGE = 40;

export const EVENT_SOCKET = [
   
]

export const walletConnector = new WalletConnectConnector({
    qrcode: true,
    rpc: {[56]: 'https://bsc-dataseed1.binance.org',[97]: 'https://data-seed-prebsc-1-s1.binance.org:8545/'},
    pollingInterval: 12000,
})

export const walletConnectorAvax = new WalletConnectConnector({
    qrcode: true,
    rpc: {[43114]: 'https://api.avax.network/ext/bc/C/rpc',[43113]: 'https://nd-151-102-486.p2pify.com/9bf8f46184eba930f76759a9414d5185/ext/bc/C/rpc'},
    pollingInterval: 12000,
})

export const walletConnectorOkex = new WalletConnectConnector({
    qrcode: true,
    rpc: {[66]: 'https://exchainrpc.okex.org',[65]: 'https://exchaintestrpc.okex.org'},
    pollingInterval: 12000,
})

export * as tokenABI from './ABI/token.json'
export * as roundABI from './ABI/round.json'

export const VERSION = 1

export const DEFAULT_ITEMS_DECK = 6;
