import {
    MaxUint,
    USER_WALLET_TYPE,
    ACTION_SEND_TRANSACTION,
    WALLET_TYPE,
    FEE_TYPE
} from "../constants";
import Web3 from "web3";
import tokenABI from "../constants/ABI/token.json";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { message } from "antd";
import cliTruncate from "cli-truncate";
import { LCDClient, Coin } from '@terra-money/terra.js';
import crypto from "crypto";
import { useUIContext } from "../hook/AppContext";

let setStatusModalNet = () => { }

export const getUser = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
};

export const RPC_BSC = getUser()?.chainInfo?.rpc || process.env.REACT_APP_BSC_PROVIDER

export const getChains = () => {
    const chains = JSON.parse(localStorage.getItem("chains"));
    return chains;
};

export const getTokens = () => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    return tokens;
};

export const getWalletType = () => {
    const user = getUser();
    return user?.walletCon
        ? user?.walletCon
        : USER_WALLET_TYPE.metamask;
};

export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const convertRound = (balance, length = 2) => {
    let cvBalance = "" + balance;

    if (Number(balance).toFixed(length) === 0) {
        cvBalance = Number(balance).toFixed(length);
    }

    if (cvBalance.indexOf(".") === -1) return cvBalance;

    // let [wholes, fractions] = cvBalance.split('.')

    const decimal = cvBalance.split(".");

    decimal[1] = decimal[1].substring(0, length);
    return decimal.join(".");
};


export const approveWallet = async (
    contractABI,
    contractAddress,//token
    marketContractAddr,//market
    setStatusModal,
    connector
) => {
    setStatusModalNet = setStatusModal;
    try {
        const web3 = new Web3(getUser()?.chainInfo?.rpc);
        const user = getUser();
        // get is approve
        const tetherContract = await new web3.eth.Contract(contractABI, contractAddress);
        const approve = await tetherContract.methods
            .allowance(user.address, marketContractAddr)
            .call();
        if (approve == 0) {
            const approveData = tetherContract.methods
                .approve(marketContractAddr, MaxUint)
                .encodeABI();
            const dataReturn = {
                chainId: 1,
                approveData: {
                    data: approveData,
                    from: user.address,
                    to: contractAddress,
                },
            };
            await handleTransactionExternalWallet(
                -1,
                dataReturn,
                handleResultTransaction,
                null,
                connector
            );
        }
        return true
    } catch (err) {
        console.log(err);
        throw err
    }
};

export const approveAllWallet = async (
    contractABI,//nftABI
    contractAddress,//market
    nftContractAddr,//nft
    setStatusModal,
    connector
) => {
    setStatusModalNet = setStatusModal
    try {
        const web3 = new Web3(getUser()?.chainInfo?.rpc);
        const user = getUser();
        // get is approve
        const sotaCont = await new web3.eth.Contract(contractABI, nftContractAddr);
        const approve = await sotaCont.methods
            .isApprovedForAll(user.address, contractAddress)
            .call();
        if (!approve) {
            const approveData = sotaCont.methods
                .setApprovalForAll(contractAddress, true)
                .encodeABI();
            const dataReturn = {
                approveAllData: {
                    data: approveData,
                    from: user.address,
                    to: nftContractAddr
                },
            };
            await handleTransactionExternalWallet(
                1,
                dataReturn,
                handleResultTransaction,
                null,
                connector
            );
        }
    } catch (err) {
        throw err;
    }
};

export const networkValidation = async (args = {}) => {
    const { chainId, requiredChainNetwork, isMetamask= false, isLogin=false } = args;
    const chain = isLogin ? requiredChainNetwork : getUser().chainInfo
    let rs = +chainId === +chain?.chainId
    if (!rs) {
        message.warning(`Please change network on Wallet to ${chain?.shortName} to continue`, 5);
        if (isMetamask) {
            const data = [
                {
                    chainId: chain?.hexChainId,
                    chainName: chain?.shortName,
                    nativeCurrency: {
                        name: chain?.shortName,
                        symbol: chain?.nativeToken,
                        decimals: 18,
                    },
                    rpcUrls: [chain?.rpc],
                    blockExplorerUrls: [chain?.url],
                },
            ];
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId:chain?.hexChainId?.toString(16)}],
                });
                await sleep(1000);
                const web3 = new Web3(chain?.rpc);
                const nchainId = await web3.eth.net.getId();
                rs = nchainId == chain?.chainId;
                return rs;
            } catch (err) {
                if (err.code === 4902) {
                    console.log("This network is not available in your metamask, please add it")
                    try {
                        await window.ethereum?.request({
                            method: "wallet_addEthereumChain",
                            params: data,
                        });
                    }catch (err) {

                    }
                }
                rs = false;
                return rs;
            }
        }
    }

    return rs;
};



export const checkTypeWallet = (name) => {
    if(name.includes("BSC")) return WALLET_TYPE.bsc
    if(name.includes("Terra")) return WALLET_TYPE.terra
    if(name.includes("AVAX")) return WALLET_TYPE.avax
    if(name.includes("OKEX")) return WALLET_TYPE.okex
}

export const getBalanceItem = async (walletAddr,contractAddress,length= 2) =>{
    const user = getUser();
    let newAmountBalance = {token: 0};
    if(user?.walletType != WALLET_TYPE.terra) {
        const networkAddress = getUser()?.chainInfo?.rpc;
        const provider = new Web3.providers.HttpProvider(networkAddress);
        const web3 = new Web3(provider);
        const tokenCont = await new web3.eth.Contract(tokenABI, contractAddress);
        let balance = await tokenCont.methods.balanceOf(walletAddr).call();
        // balance = Web3.utils.fromWei(`${balance}`, "ether");
        balance = convertRound(balance, length);
        newAmountBalance[`token`] = parseFloat(convertRound(balance, length));  
        return newAmountBalance;
    }
}


export const getBalanceToken = async (walletAddr,contractAddress,length= 2) =>{
    const user = getUser();
    let newAmountBalance = {token: 0};
    // const web3 = new Web3(Web3.givenProvider);
    // const chainId = await web3.eth.net.getId();
    // const check = await networkValidation({
    //     chainId,
    //     requiredChainNetwork: null,
    //     isMetamask: true,
    // });
    if(user?.walletType != WALLET_TYPE.terra) {
        const networkAddress = getUser()?.chainInfo?.rpc;
        const provider = new Web3.providers.HttpProvider(networkAddress);
        const web3 = new Web3(provider);
        const tokenCont = await new web3.eth.Contract(tokenABI, contractAddress);
        let balance = await tokenCont.methods.balanceOf(walletAddr).call();
        balance = Web3.utils.fromWei(`${balance}`, "ether");
        balance = convertRound(balance, length);
        newAmountBalance[`token`] = parseFloat(convertRound(balance, length));  
        return newAmountBalance;
    }
}

export const getMaxBalance = async (
    walletAddr,
    contractAddress = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
    isItems=false,
    length= 2,
) => {
    const user = getUser();
    let newAmountBalance = {token: 0};

    if(user?.walletType === WALLET_TYPE.terra) {
        const terra = new LCDClient({
            URL: user.chainInfo.rpc,
            chainID: user.chainInfo.chain_id
        });
        const [coins] = await terra.bank.balance(user.address)
        const rs = await queryContractTerra(isItems ? contractAddress : user.chainInfo.token_contract,  { balance : { address: user.address } })
        newAmountBalance[`token`] = isItems ? rs.balance : Web3.utils.fromWei(`${rs.balance}`, "mwei");
        const lunaAmount = coins.toAmino().find(c => c.denom === "uluna");
        newAmountBalance.balanceNative = Web3.utils.fromWei(`${lunaAmount?.amount || 0}`, "mwei");
        return newAmountBalance;
    }else {
        const networkAddress = getUser()?.chainInfo?.rpc;
        const provider = new Web3.providers.HttpProvider(networkAddress);
        const web3 = new Web3(provider);
        const balanceEth = await web3.eth.getBalance(walletAddr);
        const balanceEthConv = Web3.utils.fromWei(`${balanceEth}`, "ether");
        newAmountBalance.balanceNative = parseFloat(convertRound(balanceEthConv, length));
        if(!contractAddress || contractAddress == "0x0000000000000000000000000000000000000000") {
            return newAmountBalance
        }
        const tokenCont = await new web3.eth.Contract(tokenABI, contractAddress);
        let balanceSota = await tokenCont.methods.balanceOf(walletAddr).call();
        let decimals = await tokenCont.methods.decimals().call();
        if (decimals != 0 ){
            balanceSota = Web3.utils.fromWei(`${balanceSota}`, "ether");
            balanceSota = convertRound(balanceSota, length);
        }
        newAmountBalance[`token`] = parseFloat(convertRound(balanceSota, length)); 
        return newAmountBalance;
    }
    
};

export const queryContractTerra = async (contract, query) => {
    const user = getUser();
    let terra;
    // const terra = new LCDClient({
    //     URL: user.chainInfo.rpc,
    //     chainID: user.chainInfo.chain_id
    // });
    const rs = await terra.wasm.contractQuery(
        contract,
        query // query msg
    );
    return rs
}

export const connectWalletConnect = async (requiredChainNetwork) => {
    // Create a connector
    const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
        qrcodeModal: QRCodeModal,
    });
    // connector.killSession()
    // await connector.createSession({chainId: 128});
    connector.on("session_update", (error, payload) => {
        if (error) {
            throw error;
        }

        // Get updated accounts and chainId
        const { accounts, chainId } = payload.params[0];
    });

    let check = true;
    if (requiredChainNetwork) {
        check = await networkValidation({
            chainId: connector.chainId,
            requiredChainNetwork,
        });
    }
    if (check) return connector;
    else throw check;
};

export const connectMetamask = async (requiredChainNetwork) => {
    const address = getUser()?.address;
    const account = await window.ethereum.request({
        method: "eth_requestAccounts",
    });

    if (account[0].toLowerCase() !== address) {
        message.error(
            `Please connect to address wallet: ${cliTruncate(address, 20, {
                position: "middle",
            })} that you signed up`
        );
        throw false;
    }
    window.ethereum.on("accountsChanged", (account) => {
        if (account.length > 0) {
            if (account[0].toLowerCase() !== address) {
                message.error(
                    `Please connect to address wallet: ${cliTruncate(
                        address,
                        20,
                        { position: "middle" }
                    )} that you signed up`
                );
                throw false;
            }
        }
    });

    const web3 = new Web3(Web3.givenProvider);
    const chainId = await web3.eth.net.getId();
    let check = true;

    check = await networkValidation({
        chainId,
        requiredChainNetwork,
        isMetamask: true,
    });
    if (check) return web3;
    else throw check;
};

export const web3SendTransaction = async (
    web3Connected,
    chainId,
    dataReturn,
    callback,
    action,
    isSignedData
) => {
    const { from, to, value, data } = dataReturn;
    try {
        await web3Connected.eth
            .sendTransaction(
                {
                    from,
                    to,
                    value,
                    data
                },
                async (err, res) => {

                    if (res) {
                        await callback(res);
                    }
                    if (err) {
                        throw err;
                    }
                }
            )
            .on("receipt", async (rs) => {
                callback({ isRefesh: true });

                return rs;
            });
    } catch (err) {
        throw err;
    }
};

export const walletConnectSendTransaction = async (
    connect,
    chainId,
    dataReturn,
    callback,
    action,
    isSignedData
) => {
    const { from, to, value, data } = dataReturn;
    let connector
    if(connect?._provider?.wc?.peerMeta?.name === "MetaMask") {
        connector = await connectWalletConnect(chainId);
    } else {
        connector = connect?.eth
    }
    try {
        await connector
            .sendTransaction({
                from,
                to,
                data,
                value
            })
            .then(async(rs) => {
                await sleep(10000)
                const web3 = new Web3(RPC_BSC);
                let receipt = await web3.eth.getTransactionReceipt(rs);
                callback({ isRefesh: receipt ? true : false});
            });
            
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const handleTransactionExternalWallet = async (
    chainId = 1,
    dataReturn,
    callback,
    action,
    connector
) => {
    if (getWalletType() === USER_WALLET_TYPE.walletConnect ) {
        await handleTransactionWalletConnect(
            chainId,
            dataReturn,
            callback,
            action,
            connector
        );
    } else {
        await handleTransactionMetamask(chainId, dataReturn, callback, action);
    }
};

export const handleTransactionMetamask = async (
    chainId = 1,
    dataReturn,
    callback,
    action
) => {
    try {
        let web3Connected = await connectMetamask(chainId);
        if (dataReturn?.approveData) {
            let res = await web3SendTransaction(
                web3Connected,
                chainId,
                dataReturn?.approveData,
                callback,
                action
            );
        }
        if (dataReturn?.approveAllData) {
            await web3SendTransaction(
                web3Connected,
                chainId,
                dataReturn?.approveAllData,
                callback,
                action
            );
        }
        if (dataReturn?.signData) {
            let res = await web3SendTransaction(
                web3Connected,
                chainId,
                dataReturn?.signData,
                callback,
                action,
                true
            );
            return res;
        }
    } catch (err) {
        throw err;
    }
};

export const handleTransactionWalletConnect = async (
    chainId = 1,
    dataReturn,
    callback,
    action,
    connector
) => {
    try {
        
        if (dataReturn?.approveData) {
            let res = await walletConnectSendTransaction(
                connector,
                chainId,
                dataReturn?.approveData,
                callback,
                action
            );
        }
        if (dataReturn?.approveAllData) {
            await walletConnectSendTransaction(
                connector,
                chainId,
                dataReturn?.approveAllData,
                callback,
                action
            );
        }
        if (dataReturn?.signData) {
            let res = await walletConnectSendTransaction(
                connector,
                chainId,
                dataReturn?.signData,
                callback,
                action,
                true
            );
        }
    } catch (err) {
        throw err;
    }
};

const IV_SIZE = 16;
const secret = "game_market_secret_key";
let secret_key = crypto.createHash('sha256').update(String(secret)).digest('base64').substr(0, 32);
async function encrypt(data, keyString) {
        const plainText = JSON.stringify(data)
      const iv = crypto.randomBytes(IV_SIZE);
      const cipher = crypto.createCipheriv("aes-256-cbc", keyString, iv);
      let cipherText = cipher.update(Buffer.from(plainText, "utf8"));
      cipherText = Buffer.concat([cipherText, cipher.final()]);
      const combinedData = Buffer.concat([iv, cipherText]);
      const combinedString = combinedData.toString("base64");
      return combinedString;
  
  }

export const handleResultTransaction = async (rs) => {
   
    
};


export const checkBalanceGas = async (balance) => {
    if (!balance) {
        const res = await getMaxBalance(getUser()?.address, process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS)
        if (res.balanceNative < 0.01) {
            message.error("message.NFTS_INSUFFICIENT_FUNDS")
            throw false
        }
    } else {
        if (balance.balanceNative < 0.01) {
            message.error("message.NFTS_INSUFFICIENT_FUNDS")
            throw false
        }
    }
    return true
}

export const useWeb3 = () => {
    const networkAddress = getUser()?.chainInfo?.rpc;
    const provider = new Web3.providers.HttpProvider(networkAddress);
    const web3 = new Web3(provider);
    return web3;
};
const  format = (n) => {
    return  n?.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

export const convertAmount = (value) =>{
    return format(Number(Web3.utils.fromWei(`${value}`, "ether")));
}

export function formatInputNumber(numberString, decimal = 5, quantityDefault,max) {
    numberString = numberString?.replace(/[\uff01-\uff6e\uff61]/g, function (ch) {
        return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
    });
    let charCode = numberString.charCodeAt(numberString.length - 1)
    if (charCode === 12290 || charCode === 129) {
        var numberString = numberString.replace(/.$/, ".")
    }
    if (decimal === 0) {
        numberString = numberString.replace(/[^0-9]/g, "");
    } else {
        numberString = numberString.replace(/[^0-9.]/g, "");
    }
    if (numberString.length == 0) return null

    let index = numberString.indexOf(".");
    if (index > 0) {
        let string = numberString.slice(index + 1);
        string = string.replace(/[^0-9]/g, "");
        let value = parseInt(numberString.slice(0, index));
        if (value.length >= 10) value =  value.slice(0, quantityDefault)
        if (string.length > decimal) {
            string = string.slice(0, decimal);
        }
        numberString = value + "." + string;
    } else if (index === 0) {
        let string = numberString.slice(index + 1);
        string = string.replace(/[^0-9]/g, "");
        let value = 0;
        if (string.length > decimal) {
            string = string.slice(0, decimal);
        }
        numberString = value + "." + string;
    } else {
        if (numberString.length >= 10)  numberString = numberString.slice(0, quantityDefault)
        numberString = parseInt(numberString);
    }
    if (Number(numberString) > max) return max
    return numberString;
}


