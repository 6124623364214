import {
    approveWallet,
    getUser,
    handleTransactionExternalWallet,
    approveAllWallet,
    sleep,
    getMaxBalance,
    queryContractTerra,
    getWalletType
} from "../ultis/common";
// import { MsgExecuteContract } from "@terra-money/terra.js";
import Web3 from "web3";
import tokenABI from "../constants/ABI/token.json";
import roundABI from "../constants/ABI/round.json";
import { walletConnector, USER_WALLET_TYPE } from "../constants";
import { useUIContext } from "../hook/AppContext";
import {
    SignBytesFailed,
    Timeout,
    useConnectedWallet,
    UserDenied,
    TxFailed
} from "@terra-money/wallet-provider";
import { message } from "antd";
import { getTxConvertNt } from "../api";
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';

const web3 = new Web3()

const useBlockchainFunc = () => {
    const {
        statusTextModal,
        setStatusTextModal,
        setCallBackFunc,
        isAuthenticatedUser,
        setIsShowingLoginPopup,
        chains,
        setStatusModal,
        history,
        callBackFunc,
        logout
    } = useUIContext();

    const { library, activate, deactivate } = useWeb3React()
    const connectedWallet = useConnectedWallet();
    const user = getUser();
    const RPC_BSC = user?.chainInfo?.rpc || process.env.REACT_APP_BSC_PROVIDER
    const web3 = new Web3(RPC_BSC);

    const getVestingInfo = async (contractAddress) => {
        const roundContract = new web3.eth.Contract(
            roundABI,
            contractAddress
        );
        const infoRound = await roundContract.methods
            .getVestingInfo()
            .call();

        return infoRound;
    };

    const getVestingAddressInfo = async (contractAddress) => {
        const roundContract = new web3.eth.Contract(
            roundABI,
            contractAddress
        );
        const infoRound = await roundContract.methods
            .getVestingAddressInfo(getUser().address)
            .call();

        return infoRound;
    };

    const claimVesting = async (contractAddress, callback = Function()) => {
        try{
            setStatusTextModal({
                ...statusTextModal,
                pending: "Transaction pending",
                success: "Claim successfully!",
                fail: "Failed to claim",
            });
            setStatusModal({ isPending: true });
            const roundContract = new web3.eth.Contract(
                roundABI,
                contractAddress
            );
            const approveData = await roundContract.methods
                .claimVesting()
                .encodeABI();

            const dataSend = {
                signData: {
                    data: approveData,
                    from: user.address,
                    to: contractAddress,
                },
            };
            await handleTransactionExWallet(
                1,
                dataSend,
                callback,
            )

        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
        
    };

    const checkLogin = () => {
        if (!isAuthenticatedUser) {
            setIsShowingLoginPopup(true);
            return false;
        }
        return true;
    };

    const handleResultTransaction = async(rs) => {
        const {txHash, action, chain, callback} = rs
        await sleep(4000)
        if (txHash) {
            callback()
        }
    }

    

    const signTxMsg = async (contract, query, action) => {
        try {
            if(user?.chainInfo?.chain_id != connectedWallet.network.chainID) {
                throw(`Please change network on Terra to ${user?.chainInfo?.name} to continue`)
            }
            const nextTxResult = await connectedWallet.post({
                // msgs: [new MsgExecuteContract(user.address, contract, query)],
            });
            // if(action !== ACTION_SEND_TRANSACTION.allow) {
            //   await handleResultTransaction({
            //       txHash: nextTxResult.result.txhash,
            //       action,
            //       chain: user.chainInfo._id,
            //   });
            // }
            return true;
        } catch (error) {
            message.error(error.message || error);
            if (error instanceof UserDenied) {
                throw('User Denied');
              } else if (error instanceof Timeout) {
                throw('Timeout');
              } else if (error instanceof SignBytesFailed) {
                throw('Sign Bytes Failed');
              } else if (error instanceof TxFailed) {
                throw('Sign Bytes Failed');
              }
            throw error
        }
    };


    const handleResultTxDone = (res) => {
        if(res?.isRefesh) {
            setStatusModal({ isPending: false, isSuccess: true});
        }
    }

    const checkMultiAllow = async(listItem) => {
        try {
            if(user?.chainInfo?.chain_id != connectedWallet.network.chainID) {
                throw(`Please change network on Terra to ${user?.chainInfo?.name} to continue`)
            }
        const tokenAddrCall = listItem.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.tokenAddress === value.tokenAddress
            ))
        )
        const res = await Promise.all(
            tokenAddrCall.map(async (item) => {
                return queryContractTerra(item.tokenAddress, {
                    approved_for_all: { owner: user.address },
                });
            })
        );
        const multiMsg = []
        res.map((el,index) => {
            if (!el.operators.find((ele) => ele.spender === user.chainInfo.market_contract)) {
                // multiMsg.push(new MsgExecuteContract(user.address, tokenAddrCall[index].tokenAddress, {approve_all: {
                //     operator: user.chainInfo.market_contract,
                // },}))
            }
        })
        if(multiMsg.length >0) {
            const nextTxResult = await connectedWallet.post({
                msgs: multiMsg
            });
            return true
        }
        } catch (error) {
            throw(error.message|| error);
        }
    }

    const handleTransactionExWallet = async (
        chainId = 1,
        dataReturn,
        callback,
        action
    ) => {
        await checkConnector()
        await handleTransactionExternalWallet(
            chainId,
            dataReturn,
            callback,
            action,
            library
        );
    };

    const handleApproveWallet = async (
        contractABI,
        contractAddress,//token
        marketContractAddr,//market
        setStatusModal

    ) => {
        await checkConnector()
        const res = await approveWallet(
            contractABI,
            contractAddress,//token
            marketContractAddr,//market
            setStatusModal,
            library
        );
        return res
    };

    const handleApproveAllWallet = async (
        contractABI,//nftABI
        contractAddress,//market
        nftContractAddr,//nft
        setStatusModal

    ) => {
        await checkConnector()
        await approveAllWallet (
            contractABI,//nftABI
            contractAddress,//market
            nftContractAddr,//nft
            setStatusModal,
            library
        );
    };

    const checkConnector = async () => {
        if (!library && getWalletType() === USER_WALLET_TYPE.walletConnect) {
        // const connector = await connectWalletConnect()
        // connector.killSession();
            walletConnector.close()
            deactivate()
            logout();
            window.location.reload();
        }  
    };

    return {
        checkLogin,
        getVestingInfo,
        getVestingAddressInfo,
        claimVesting
    };
};

export default useBlockchainFunc;
