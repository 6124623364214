import React, { useState } from "react";
import "./style.scss";
import { Switch} from "antd";
import RoundItem from "./round-item";
import { ROUND_LIST } from "../../../constants";
import { withContext } from "../../../hook/AppContext";


function Vesting(props) {
  const [myAllocation, setMyAllocation] = useState(false)
  const [quantity, setQuantity] = useState(0)

  const onChangeSwitch = (value) => {
    setMyAllocation(value)
  }
  return (
  <div className="home-container">
    <div className="my-allocation"> My Allocation ( {quantity} ) <Switch
                      checked={myAllocation}
                      onChange={onChangeSwitch}
                    />
    </div>
    {ROUND_LIST?.map((el, index) => <RoundItem key={index} setQuantity={setQuantity}  myAllocation={myAllocation} contractAddress={el}/>)}
  </div>
  );
}

export default withContext(Vesting);
