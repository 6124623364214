import React, { useEffect, useMemo, useState } from "react";
import { withContext } from "../../../../hook/AppContext";
import "./style.scss";
import cliTruncate from "cli-truncate";
import copy from "copy-to-clipboard";
import { Menu, message } from "antd";
import {
  getUser,
  getWalletType,
  getMaxBalance,
} from "../../../../ultis/common";
import { USER_WALLET_TYPE, walletConnector,walletConnectorAvax, walletConnectorOkex, CHAIN } from "../../../../constants";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";
import {connectWalletConnect} from "../../../../ultis/common";
import CustomDropdown from "../../../../components/elements/dropdown-custom";
import {useWallet} from '@terra-money/wallet-provider'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'

function UserLoggedIn(props) {
  const {
    user,
    logout,
    isAuthenticatedUser,
    setStatusModal,
    setStatusTextModal,
    balanceWallet,
    getBalanceWallet,
    chains,
    setUser
  } = props;
  const [isMobile] = useWindowResizeMobile(769);
  const {disconnect} = useWallet();

  const signOut = async () => {
    if (getWalletType() === USER_WALLET_TYPE.walletConnectAvax){
      walletConnectorAvax.close()
    }
    if (getWalletType() === USER_WALLET_TYPE.walletConnect){
      walletConnector.close()
    }
    if (getWalletType() === USER_WALLET_TYPE.walletConnectOkex){
      walletConnectorOkex.close()
    }
    logout();
    disconnect()
    window.location.reload();
  };

  const copyCodeToClipboard = () => {
    copy(user?.address);
  };

  useEffect(() => {
    setUser({
      ...user,
      chainInfo: CHAIN,
    });
  }, []);


  const dropdownMenu = (
    <Menu className="dropdown-menu-login">
      {isMobile && <><Menu.Item>
        <div className="address-block chain-block">
          <img className="logo-token" src={getUser()?.chainInfo?.image} />
          <span>{getUser()?.chainInfo?.shortName}</span>
        </div>
      </Menu.Item>
      <Menu.Divider></Menu.Divider></>}
      <Menu.Item
        key="user-address"
        className="copy-address"
        onClick={copyCodeToClipboard}
      >
        {user?.address
          ? cliTruncate(user?.address, 10, { position: "middle" })
          : ""}
        <img src="/images/copy.svg" />
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      <Menu.Item key="sign-out">
        <a onClick={signOut} className="item-text">
          Sign out
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="user-logged-block">
      {!isMobile && <div className="address-block chain-block">
        <img className="logo-token" src={user?.chainInfo?.image} />
        <span>{user?.chainInfo?.shortName}</span>
      </div>}
      <div className="dropdown-menu">
        <CustomDropdown
          overlay={dropdownMenu}
          placement="bottomRight"
          trigger={["click"]}
        >
          {!isMobile ? <div className="address-block">
            {user?.address
              ? cliTruncate(user?.address, 10, { position: "middle" })
              : ""}
          </div> : 
          <img src="/images/user-mobile.svg"/>}
        </CustomDropdown>
      </div>
      
    </div>
  );
}

export default withContext(UserLoggedIn);
