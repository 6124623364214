import "./style.scss";

import { Button } from "antd";

function CustomButton({ children, ...props }) {
  const {
    name,
    classNames,
    style,
    block,
    danger,
    disabled,
    ghost,
    href,
    htmlType,
    icon,
    iconLeft,
    iconRight,
    loading,
    shape,
    size,
    target,
    onClick,
    color,
  } = props;

  let overrideClassNames = [classNames];

  const renderContent = () => {
    return (
      <>
        {typeof iconLeft !== "undefined" ? iconLeft : ""}
        {children}
        {typeof iconRight !== "undefined" ? iconRight : ""}
      </>
    );
  };

  const initArgs = () => {
    if (size === "medium") {
      overrideClassNames.push("atn-btn-size-medium");
    }

    if (size === "large") {
      overrideClassNames.push("atn-btn-size-large");
    }

    if (size === "small") {
      overrideClassNames.push("atn-btn-size-small");
    }

    if (color === "green") {
      overrideClassNames.push("atn-btn-color-green");
    }
    if (color === "gray") {
      overrideClassNames.push("atn-btn-color-gray");
    }
    if (color === "brown") {
      overrideClassNames.push("atn-btn-color-brown");
    }
    if (color === "red-blur") {
      overrideClassNames.push("atn-btn-color-red");
    }

    if (color === "red") {
      overrideClassNames.push("red-btn");
    }

    if (color === "gardient") {
      overrideClassNames.push("atn-btn-color-gardient");
    }

    if (classNames?.length > 0) {
      overrideClassNames = overrideClassNames.concat(classNames);
    }
  };

  initArgs();

  return (
    <Button
      name={name}
      className={overrideClassNames.join(" ")}
      style={style}
      block={block}
      danger={danger}
      disabled={disabled}
      ghost={ghost}
      href={href}
      htmlType={htmlType}
      icon={icon}
      loading={loading}
      shape={shape}
      size={size}
      target={target}
      onClick={onClick}
    >
      {renderContent()}
    </Button>
  );
}

export default CustomButton;
