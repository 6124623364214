import React, { useEffect } from "react";
import Vesting from "../../components/templates/vesting";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function VestingPage(props) {
    return (
        <SiteLayout >
            <Vesting />
        </SiteLayout>
    );
}

export default VestingPage;
