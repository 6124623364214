import React from "react";
import "./style.scss";
import CustomModal from "../../elements/modal";
import CustomButton from "../../elements/button";

const ProgressModal = (props) => {
  const { statusModal, title, setStatusModal } = props;

  const onCancel = () => {
    setStatusModal({});
  };

  return (
    <>
      <CustomModal
        className="waiting-modal status-modal"
        footer={null}
        closable={false}
        visible={statusModal.isPending}
        zIndex={10000}
      >
        <div className="confirm-block notify-content">
          <img
            className="loading-icon"
            src="/images/loading-icon-2.png"
            alt="loading"
          />
          <div className="title pending">{title.pending}</div>
          <div className="else">{`Please wait...`}</div>
        </div>
      </CustomModal>

      <CustomModal
        className="waiting-modal status-modal"
        footer={null}
        visible={statusModal.isFailed}
        onCancel={onCancel}
        closable={false}
        zIndex={10000}
      >
        <div className="d-flex align-items-center justify-content-center notify-content">
          <img className="status-icon" src="/images/fail.svg" alt="fail" />
          <div>
            <div
              className={`title`}
            >
              {title.fail}
            </div>
          </div>
          <CustomButton
            children={"Close"}
            classNames="orange-btn btn-item"
            shape="circle-custom"
            onClick={onCancel}
          />
        </div>
      </CustomModal>

      <CustomModal
        className="waiting-modal status-modal"
        footer={null}
        visible={statusModal.isSuccess}
        onCancel={onCancel}
        closable={false}
        zIndex={10000}
      >
        <div className="notify-content">
          <img
            className="status-icon"
            src="/images/success.svg"
            alt="success"
          />

          <div>
            <div className="title">{title?.success}</div>
          </div>
          <CustomButton
            children={"Close"}
            classNames="orange-btn btn-item"
            shape="circle-custom"
            onClick={onCancel}
          />
        </div>
      </CustomModal>

      <CustomModal
        className="waiting-modal status-modal"
        footer={null}
        closable={false}
        visible={statusModal.isNotify}
      >
        <div className="confirm-block notify-content">
          <div className="title">{title.noti}</div>
          <CustomButton
            children={"Close"}
            classNames="orange-btn btn-item"
            shape="circle-custom"
            onClick={onCancel}
          />
        </div>
      </CustomModal>

      <CustomModal
        className="waiting-modal status-modal"
        footer={null}
        closable={false}
        visible={statusModal.isCheckBalance}
      >
        <div className="confirm-block notify-content">
          <div className="title">{title.checkBalance}</div>
          <CustomButton
            children={"Close"}
            classNames="orange-btn btn-item"
            shape="circle-custom"
            onClick={onCancel}
          />
        </div>
      </CustomModal>
      <CustomModal
        className="waiting-modal status-modal change-net"
        footer={null}
        // closable={false}
        width={750}
        visible={
          statusModal?.isChangeNet?.value !== "" &&
          statusModal?.isChangeNet?.type
        }
        onCancel={onCancel}
      >
        <div className="title-popup">
          {statusModal?.isChangeNet?.title || ""}
        </div>
        <div className="wallet-box-line"></div>
        <div className="confirm-block notify-content">
          <div className="title-change">{statusModal?.isChangeNet?.value}</div>
        </div>
      </CustomModal>
      <CustomModal
        className="waiting-modal status-modal"
        footer={null}
        closable={false}
        visible={statusModal.isUnbox}
        zIndex={10000}
        destroyOnClose={true}
      >
        <div className="confirm-block notify-content">
          <div className="title pending">{title.pending}</div>
          <div className="else">{`Please wait...`}</div>
        </div>
      </CustomModal>
    </>
  );
};

export default ProgressModal;
