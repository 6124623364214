import React, {  useState } from "react";
import "./style.scss";
import moment from 'moment';
import { withContext } from "../../../hook/AppContext";

function CountDownBox(props) {
  const { time_end, callback } = props;
  const [time, setTime] = useState({
    days:0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

    setTimeout(() => {
      const now = moment().unix();
      const countdown = time_end - now;
      if (countdown >= 0) {
        const days = Math.floor(countdown / 60 / 60 / 24);
        const hours = Math.floor((countdown / 60 / 60) % 24);
        const minutes = Math.floor((countdown / 60) % 60);
        const seconds = Math.floor(countdown % 60);

        setTime({
          days: Number(days),
          hours: Number(hours),
          minutes: minutes,
          seconds: seconds
        })

      }else callback(true)
    }, 1000);

  const renderNumber = (value) => {
    if( value < 10) return `0${value}`
    else return value
  }
  

  return (
    <div className="box-countdown">
      <div className="time-text">
        <div className="time-value">{renderNumber(time.days)}</div>
        DAYS
      </div>
      <div className="time-text">
        <div className="time-value">{renderNumber(time.hours)}</div>
        HOURS
      </div>
      <div className="time-text">
        <div className="time-value">{renderNumber(time.minutes)}</div>
        MINUTES
      </div>
      <div className="time-text">
        <div className="time-value">{renderNumber(time.seconds)}</div>
        SECONDS
      </div>
    </div>
  );
}

export default withContext(CountDownBox);
