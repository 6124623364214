import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { getChainOptions, WalletProvider } from '@terra-money/wallet-provider';


// network information
const mainnet = {
  name: 'mainnet',
  chainID: 'columbus-5',
  lcd: 'https://lcd.terra.dev',
};

const testnet = {
  name: 'testnet',
  chainID: 'bombay-12',
  lcd: 'https://bombay-lcd.terra.dev',
};

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
    <WalletProvider {...chainOptions} defaultNetwork={testnet}>
      <App/>
    </WalletProvider>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
